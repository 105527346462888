input[type="date"].form-control {
    line-height: 1;
  }
  .input-group-addon {
    background-color: #f9f9f9;
    border-color: #e3e3e3;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-left-width: 0px;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
    justify-content: center;
    display: grid;
  }
  
  input[type="date"] {
    position: relative;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    border: none;
  }
  
  /* adjust increase/decrease button */
  input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
  }
  
  /* adjust clear button */
  input[type="date"]::-webkit-clear-button {
     z-index: 1;
  }